import { encodeQuery, decodeQuery } from './query';
import { encodeDocumentType, decodeDocumentType } from './documentType';
import { encodePageNumber, decodePageNumber } from './page';
import { encodeSortBy, decodeSortBy } from './sortBy';

export * from './documentType';
export * from './page';
export * from './sortBy';
export * from './query';

export const encoders = {
  encodeQuery,
  encodeDocumentType,
  encodePageNumber,
  encodeSortBy,
};

export const decoders = {
  decodeQuery,
  decodeDocumentType,
  decodePageNumber,
  decodeSortBy,
};
